import Vue from 'vue';
import VueRouter, { RouteConfig } from 'vue-router';
import Login from '../views/Login.vue';

Vue.use(VueRouter);

const routes: Array<RouteConfig> = [
  {
    path: '/oauth/authorize',
    name: 'Login',
    component: Login,
    meta: {
      loginType: 'oauth'
    }
  },
  {
    path: '/login',
    name: 'Login',
    component: Login,
    meta: {
      loginType: 'cookie'
    }
  }
];

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
});

export default router;
