



















































import { Component, Vue } from 'vue-property-decorator';
import axios from 'axios';

@Component({})
export default class Login extends Vue {
  public username: string = '';
  public password: string = '';

  public errorMessage: string = '';
  public redirectActionUri: string = '';

  public get queryError (): string {
    return this.$route.query.error as string;
  }

  public get loginType (): string {
    return this.$route.meta?.loginType || '';
  }

  public get targetName (): string {
    return this.$t(`application.${this.target}.name`) as string;
  }

  public get target (): string {
    return this.$route.query.target as string;
  }

  public get redirectUri (): string {
    return this.$route.query.redirect_uri as string;
  }

  public login (): void {
    if (this.loginType === 'cookie') {
      const form: HTMLFormElement = (this.$refs.loginForm as any).$el;
      form.action = '/api/auth/login';
      form.submit();
    } else if (this.loginType === 'oauth') {
      axios.post(
        '/api/oauth/authorize?response_mode=json',
        {
          response_type: this.$route.query.response_type,
          grant_type: 'password',
          username: this.username,
          password: this.password,
          state: this.$route.query.state,
          redirect_uri: this.redirectUri,
          client_id: this.$route.query.client_id,
          scope: this.$route.query.scope
        }
      )
        .then((response) => {
          this.errorMessage = '';
          console.log(response);
          if (response.data.redirect_uri) {
            this.redirectActionUri = response.data.redirect_uri;
            window.location.href = response.data.redirect_uri;
          }
        })
        .catch((err) => {
          console.error(err);
          console.error({ ...err });
          this.redirectActionUri = '';
          this.errorMessage = err?.response?.data?.message || err;
        });
    }
  }
}
